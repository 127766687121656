import styles from '../styles.module.css';

const FirstProductAI = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1>
          Программный комплекс для анализа видеопотока на базе искусственного
          интеллекта
        </h1>
        <h2>В решение вошли три модуля:</h2>
        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>
              Программный комплекс для анализа видеопотока на базе
              искусственного интеллекта
            </li>
            <li>
              Модуль подсчета количества заданных объектов, присутствующих в
              кадре видеопотока
            </li>
            <li>Модуль дескриптивной аналитики и визуализации</li>
          </ul>
        </nav>
        <div className="d-flex flex-row justify-between mt-45">
          <img
            style={{ width: '21vw', height: 'auto' }}
            src="/img/document-1.jpg"
            alt="document 1"
          />
          <img
            style={{ width: '21vw', height: 'auto' }}
            src="/img/document-2.jpg"
            alt="document 2"
          />
          <img
            style={{ width: '21vw', height: 'auto' }}
            src="/img/document-3.jpg"
            alt="document 3"
          />
        </div>
        <h2>ОБЩИЕ СВЕДЕНИЯ</h2>
        <h2>Полное наименование Продукта</h2>
        <br />
        <p>
          Программный комплекс для анализа видеопотока на базе искусственного
          интеллекта. (Далее система)
        </p>
        <br />
        <p>Код Продукта: PKS_AI-1.1</p>
        <br />
        <p>
          Система спроектирована и функционирует по модели Клиент-Сервер. Общая
          схема Системы представлена на рисунке ниже
        </p>
        <h2>Задачи решаемые Системой</h2>
        <br />
        <p>
          Система предназначена для круглосуточного режима эксплуатации и решает
          следующие задачи:
        </p>
        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>
              1. Детекции заданных классов объектов в поле зрения камер
              видеонаблюдения;{' '}
            </li>
            <li>
              2. Подсчет численности распознанных объектов. Как в отдельности,
              так и по совокупным данным, полученным со всех камер
              видеонаблюдения;{' '}
            </li>
            <li>3. Накопление архивных данных; </li>
            <li>
              4. Передача данных на сервер, согласно таблице маршрутизации;
            </li>
            <li>
              5. Формирование и отображение графиков и диаграмм по
              статистическим данным.{' '}
            </li>
          </ul>
        </nav>
        <h2>Функциональные возможности системы</h2>
        <br />
        <p>
          Система предназначенного для обработки нейросетевыми алгоритмами
          данных с различных камер видеонаблюдения.
        </p>
        <br />
        <p>
          Результатом работы являются численные данные для записи в БД в
          формате:
        </p>
        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>1. Дата и Время проведения анализа; </li>
            <li>2. Идентификатор объекта из справочника объектов; </li>
            <li>3. Накопление архивных данных; </li>
            <li>
              4. Идентификатор видеокамеры в помещении в объекте из справочника
              видеокамер в помещении объекта;{' '}
            </li>
            <li>5. Идентификатор сектора анализа видеокамеры; </li>
            <li>6. Количество распознанных объектов.</li>
          </ul>
        </nav>
        <br />
        <p>
          Эти данные используются для дальнейшей обработки другими модулями
          программного комплекса. Формируется архив. Это позволяет, с
          минимальными доработками, создавать законченные решения для самых
          различных задач. В настоящее время, в кадре видеопотока распознаётся
          более 80 различных объектов.
        </p>
        <h2>Работа Системы строится по следующему алгоритму:</h2>
        <div className="d-flex justify-center mt-20">
          <img
            className={styles.block_scheme}
            style={{ width: '41vw', height: 'auto' }}
            src="/img/block-scheme.jpg"
            alt="Блок-схема"
          />
        </div>
        <br />
        <p>
          Решение легко встраивается в существующие информационные систему
          корпоративного уровня.
        </p>
        <p>Результаты анализа предоставляться по запросу через API. </p>
      </div>
    </div>
  );
};

export default FirstProductAI;
