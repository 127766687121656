import styles from '../styles.module.css';

const SecondProductAI = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1>Система контроля производственных процессов</h1>
        <h2>В решение вошли три модуля:</h2>
        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>
              Контроль производственных процессов с применением технологий
              искусственного интеллекта
            </li>
            <li>
              Модуль дистанционного контроля температуры технологического
              оборудования
            </li>
            <li>Модуль дескриптивной аналитики и визуализации</li>
          </ul>
        </nav>
        <div className="d-flex flex-row justify-between mt-45">
          <img
            style={{ width: '21vw', height: 'auto' }}
            src="/img/document-5.jpg"
            alt="document 5"
          />
          <img
            style={{ width: '21vw', height: 'auto' }}
            src="/img/document-4.jpg"
            alt="document 4"
          />
          <img
            style={{ width: '21vw', height: 'auto' }}
            src="/img/document-3.jpg"
            alt="document 3"
          />
        </div>
        <p className="mt-45">
          Человек — главное звено в сложном технологическом процессе, его
          контролёр и регулировщик. Однако человеку присущи естественные
          ограничения и способность совершать ошибки.{' '}
        </p>
        <br />
        <p>
          Цель разработки - минимизация ручного труд и влияния человеческого
          фактора на важные производственные процессы.
        </p>
        <br />
        <p>
          Объект контроля: технологическое оборудование на различных
          производствах. Оборудование может располагаться, как на открытых
          площадках, так и в закрытых конструкциях.
        </p>
        <br />
        <p>Система спроектирована и функционирует по модели Клиент-Сервер. </p>
        <br />
        <p>
          Система предназначена для круглосуточного режима эксплуатации и решает
          следующие задачи:
        </p>

        <h2>Решение основано на применении технологий:</h2>

        <nav className={styles.products_navbar}>
          <ol className={styles.products}>
            <li>1. Компьютерного зрения; </li>
            <li>2. Искусственного интеллекта; </li>
            <li>3. Глубокого машинного обучения. </li>
          </ol>
        </nav>

        <br />

        <p>
          Серверная часть программного комплекса, предназначенного для обработки
          нейросетевыми алгоритмами данных с различных клиентских датчиков.
        </p>

        <h2>Таких как:</h2>

        <nav className={styles.products_navbar}>
          <ol className={styles.products}>
            <li>1. Тепловизор; </li>
            <li>2. Лидар; </li>
            <li>3. Камера видеонаблюдения. </li>
          </ol>
        </nav>

        <h2>Результат работы системы:</h2>

        <nav className={styles.products_navbar}>
          <ol className={styles.products}>
            <li>1. Обнаружение и идентификация объектов; </li>
            <li>2. Обнаружение и идентификация событий; </li>
            <li>3. Обнаружение отклонений (аномалий) от регламента. </li>
            <li>
              4. Предоставление доступа к информации об объекте и событии.{' '}
            </li>
          </ol>
        </nav>

        <br />

        <p>
          Для обеспечения возможности работы на оборудовании, расположенном в
          закрытых конструкциях, разработан и производится беспроводной
          тепловизионный сенсор модели СISD-1001.
        </p>

        <div className="d-flex justify-center mt-20">
          <img
            className={styles.block_scheme}
            style={{ width: '41vw', height: 'auto' }}
            src="/img/sensor.jpg"
            alt="Сенсор модели СISD-1001"
          />
        </div>
        <br />
        <p>
          Поскольку аналитическая часть решения реализована на нейросетевых
          технологиях, система «до обучается на месте», с учётом всех «местных
          факторов». Предиктивный анализ основан на исследовании взаимосвязи
          входных и выходных параметров в исторических данных. Математическая
          модель связи входных и выходных параметров позволяет делать прогноз на
          новых данных и сравнивать предсказанное значение с измеренным.
          Отклонение разницы выше порогового значения будет означать наличие
          аномалии в значении анализируемого параметра узла. Для исключения
          ошибочности прогноза вывод об аномалии делается на основе трех подряд
          идущих превышений порога отклонения.
        </p>
      </div>
    </div>
  );
};

export default SecondProductAI;
