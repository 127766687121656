import { useState } from 'react';

const Projects = () => {
  const [isInfoFirstOpened, setIsInfoFirstOpened] = useState(false);
  const [isInfoSecondOpened, setIsInfoSecondOpened] = useState(false);
  const [isInfoThirdOpened, setIsInfoThirdOpened] = useState(false);

  return (
    <div className="content">
      <div className="infoPages">
        <h1>Наши проекты</h1>
        <p id="firstP" className="mt-45">
          По тематике AI (Искусственного Интеллекта), у компании есть хорошие
          компетенции в трёх областях:
        </p>
        <p className="mt-25 ml-30">
          {' '}
          1. Обработка естественного языка (NLP);
          <br />
          2. Компьютерное зрение (CV);
          <br />
          3. Предиктивная аналитика» (machine learning и statistical modeling).
        </p>
        <p className="mt-30">
          В областях NLP и ML&SL, разработанное программное обеспечение,
          используется в проекте «Цифровой мир образования» для поддержки
          интерактивности и оценки уровня прохождения симуляции. А в области CV
          - «Мы всех посчитали»: Аналитика заполняемости и аналитика в торговом
          зале.
        </p>

        <div id="tabFirst" className="dropDown d-flex flex-column">
          <div className="d-flex flex-row justify-between">
            <h3
              className="d-flex align-start cu-p"
              onClick={() => setIsInfoFirstOpened(!isInfoFirstOpened)}
            >
              Цифровой Мир Образования
            </h3>
            <h3
              onClick={() => {
                setIsInfoFirstOpened(!isInfoFirstOpened);
                setIsInfoSecondOpened(false);
                setIsInfoThirdOpened(false);
              }}
              className={`${
                isInfoFirstOpened ? 'd-none' : 'd-flex align-end cu-p mr-20'
              }`}
            >
              +
            </h3>
            <h3
              onClick={() => setIsInfoFirstOpened(!isInfoFirstOpened)}
              className={`${
                isInfoFirstOpened ? 'd-flex align-end cu-p mr-20' : 'd-none'
              }`}
            >
              х
            </h3>
          </div>
          <div
            className={`${
              isInfoFirstOpened ? 'd-flex flex-column mt-15' : 'd-none'
            }`}
          >
            <p className="mt-10">
              Разработка программного обеспечения для проекта «Цифровой мир
              образования» ведётся в тесном сотрудничестве с образовательными
              учреждениями. При этом география взаимодействия – от Москвы до
              Владивостока. Так совместно с ДВФУ в 2019 году был организован
              трёхдневные семинар с участием преподавателей и учеников
              общеобразовательных школ и СУЗов. В 2020 году компания приняла
              участие на мероприятиях в Сколково.
            </p>

            <div className="d-flex flex-row justify-around mt-40 mb-35">
              <img
                style={{ width: '11vw', height: 'auto' }}
                src="/img/info-projects-2.jpg"
                alt="Photo2"
              />
              <img
                style={{ width: '26.6vw', height: 'auto' }}
                src="/img/info-projects-4.jpg"
                alt="Photo4"
              />
              <img
                style={{ width: '12vw', height: 'auto' }}
                src="/img/info-projects-3.jpg"
                alt="Photo3"
              />
            </div>

            <p className="mt-20">В рамках проекта были разработаны:</p>
            <p className="mt-20 ml-30">
              1. 6 VR симуляций;
              <br />
              2. Необходимое серверное и клиентское программное обеспечение;
              <br />
              3. Методика ведения уроков с использованием VR симуляций;
              <br />
              4. Программа повышения квалификации преподавателей.
            </p>
            <p className="mt-20">
              Для организации диалогов внутри VR симуляции разработаны
              оригинальные, нейросетевые методы анализа реплики ученика и
              формирования ответной фразы компьютерного аватара. Это позволяет
              адаптироваться к речи учащегося и поддержать диалог на понятном
              для него уровне сложности. В свою очередь, появляется возможность
              пройти VR симуляцию учащимся даже с ограниченными знаниями языка.
              По итогам прохождения учащимся VR симуляции, с помощью методов
              предиктивной аналитики, формируется оценка прохождения симуляции.
              Оценка формируется по 100 бальной шкале. Для формирования оценки
              используются более 15 показателей. И вот наиболее важные из них:
            </p>
            <p className="mt-20 ml-30">
              1. Время формирования фразы;
              <br />
              2. Полнота использования словарного запаса;
              <br />
              3. Сложность сформированной фразы.
            </p>
            <p className="mt-20">
              Используя результаты компьютерного анализа и оценку прохождения
              симуляции, преподаватель может подобрать индивидуальный план
              занятий.
            </p>
            <p className="mt-20">
              И в 2021г. для проверки работоспособности решения, на базе МАОУ
              СОШ 217 г.Новосибирск был выполнен пилотный проект «Апробация
              речевого тренажёра с виртуальной реальностью». В августе месяце,
              специалистами Компании в течении трёх дней проводилось обучение
              преподавателей методике проведения уроков и практические занятия с
              привлечением учащихся. Далее, в течении двух месяцев,
              преподаватели английского языка использовали в учебном процессе
              разработанное компанией VR симуляции.
            </p>

            <div className="d-flex flex-row justify-around mt-40 mb-35">
              <img
                style={{ width: '26.6vw', height: 'auto' }}
                src="/img/info-projects-5.jpg"
                alt="Photo5"
              />
              <img
                style={{ width: '26.6vw', height: 'auto' }}
                src="/img/info-projects-6.jpg"
                alt="Photo6"
              />
            </div>

            <div className="d-flex flex-row mt-10 mb-15">
              <a
                href="https://cloud.mail.ru/public/vNuw/mB5zSjduz"
                target="_blank"
              >
                Фотогалерея проекта
              </a>
              <a
                className="ml-35"
                href="https://www.youtube.com/watch?v=UkJDdJRQwFU&ab_channel=%D0%94%D0%B0%D0%BD%D0%B0%D0%9A%D0%B0%D1%80%D0%BE%D0%BF%D1%87%D1%83%D0%BA"
                target="_blank"
              >
                Видео
              </a>
            </div>
          </div>
        </div>

        <div className="dropDown d-flex flex-column">
          <div className="d-flex flex-row justify-between">
            <h3
              className="d-flex align-start cu-p"
              onClick={() => setIsInfoSecondOpened(!isInfoSecondOpened)}
            >
              Аналитика заполняемости
            </h3>
            <h3
              onClick={() => {
                setIsInfoSecondOpened(!isInfoSecondOpened);
                setIsInfoFirstOpened(false);
                setIsInfoThirdOpened(false);
              }}
              className={`${
                isInfoSecondOpened ? 'd-none' : 'd-flex align-end cu-p mr-20'
              }`}
            >
              +
            </h3>
            <h3
              onClick={() => setIsInfoSecondOpened(!isInfoSecondOpened)}
              className={`${
                isInfoSecondOpened ? 'd-flex align-end cu-p mr-20' : 'd-none'
              }`}
            >
              х
            </h3>
          </div>
          <div
            className={`${
              isInfoSecondOpened ? 'd-flex flex-column' : 'd-none'
            }`}
          >
            <p className="mt-20">
              В 2023году, компания выполнила разработку программного обеспечения
              и пилотный проект на тему «Учёт заполняемости спортивных
              сооружений». Работы выполнялись в рамках исполнения обязательств
              по госконтракту №2023/ОК-п25-77 от 02.10.2023 г. Ядром созданного
              решения был разработанный Компанией продукт «Модуль подсчета
              количества заданных объектов, присутствующих в кадре видеопотока».
              В качестве источников первичной информации, используется
              оборудование существующей системы видеонаблюдения. Результатом
              работы созданного решения, является числовая информация, пригодная
              для дальнейшей статистической обработки.
            </p>
            <p className="mt-20">Вот, так работает созданное решение:</p>

            <div className="d-flex flex-row justify-center mt-40 mb-35">
              <img
                style={{ width: '63vw', height: 'auto' }}
                src="/img/scheme.PNG"
                alt="Photo7"
              />
            </div>

            <div className="d-flex flex-row justify-around mb-25">
              <img
                style={{ width: '32vw', height: 'auto' }}
                src="/img/info-projects-8.jpg"
                alt="Photo8"
              />
              <img
                style={{ width: '32vw', height: 'auto' }}
                src="/img/info-projects-9.jpg"
                alt="Photo9"
              />
            </div>

            <div className="d-flex flex-row justify-around mb-35">
              <img
                style={{ width: '32vw', height: 'auto' }}
                src="/img/info-projects-10.jpg"
                alt="Photo10"
              />
              <img
                style={{ width: '32vw', height: 'auto' }}
                src="/img/info-projects-11.jpg"
                alt="Photo11"
              />
            </div>
          </div>
        </div>

        <div
          className={`${
            isInfoThirdOpened || isInfoSecondOpened || isInfoFirstOpened
              ? 'dropDown d-flex flex-column'
              : 'dropDown d-flex flex-column tab'
          }`}
        >
          <div className="d-flex flex-row justify-between">
            <h3
              className="d-flex align-start cu-p"
              onClick={() => setIsInfoThirdOpened(!isInfoThirdOpened)}
            >
              Аналитика в торговом зале
            </h3>
            <h3
              onClick={() => {
                setIsInfoThirdOpened(!isInfoThirdOpened);
                setIsInfoSecondOpened(false);
                setIsInfoFirstOpened(false);
              }}
              className={`${
                isInfoThirdOpened ? 'd-none' : 'd-flex align-end cu-p mr-20'
              }`}
            >
              +
            </h3>
            <h3
              onClick={() => setIsInfoThirdOpened(!isInfoThirdOpened)}
              className={`${
                isInfoThirdOpened ? 'd-flex align-end cu-p mr-20' : 'd-none'
              }`}
            >
              х
            </h3>
          </div>
          <div
            className={`${isInfoThirdOpened ? 'd-flex flex-column' : 'd-none'}`}
          >
            <p className="mt-20">
              На основе разработанного Компанией продукта «Модуль подсчета
              количества заданных объектов, присутствующих в кадре видеопотока»
              был выполнен проект анализа загруженности торгового зала, и
              эффективности работы персонала. Анализ производится в режиме
              реального времени с использованием видеокамер, расположенных в
              торговом зале. Результаты анализа представляйся в виде таблиц,
              графиков, диаграмм.
            </p>

            <div className="d-flex flex-row justify-around mt-40 mb-35">
              <img
                style={{ width: '32vw', height: 'auto' }}
                src="/img/info-projects-12.jpg"
                alt="Photo12"
              />
              <img
                style={{ width: '32vw', height: 'auto' }}
                src="/img/info-projects-13.jpg"
                alt="Photo13"
              />
            </div>

            <p className="mt-20">
              Результаты анализа используются для оптимизации численности
              рабочих мест и для оценки эффективности работы персонала.
              Например, такие показатели, как:
            </p>
            <p className="mt-25 ml-30 mb-30">
              1. Минимально необходимое количество персонала;
              <br />
              2. Время обслуживания на кассе одного клиента.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
