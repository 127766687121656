import React from 'react';

const ObjectsOfCopyright = () => {
  return (
    <div className="content">
      <div className="d-flex flex-row justify-around mt-40 mb-35">
        <img
          style={{ width: '22vw', height: 'auto' }}
          src="/img/document-1.jpg"
          alt="document-1"
        />
        <img
          style={{ width: '22vw', height: 'auto' }}
          src="/img/document-2.jpg"
          alt="document-2"
        />
        <img
          style={{ width: '22vw', height: 'auto' }}
          src="/img/document-3.jpg"
          alt="document-3"
        />
      </div>
      <div className="d-flex flex-row justify-around mt-40 mb-35">
        <img
          style={{ width: '22vw', height: 'auto' }}
          src="/img/document-4.jpg"
          alt="document-4"
        />
        <img
          style={{ width: '22vw', height: 'auto' }}
          src="/img/document-5.jpg"
          alt="document-5"
        />
      </div>
    </div>
  );
};

export default ObjectsOfCopyright;
