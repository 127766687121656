import styles from '../styles.module.css';

const ThirdProjectAI = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1>Контроль торгового зала</h1>
        <h2>2024 год</h2>
        <p className="mt-25">
          Совместно с одной из крупных торговых сетей, выполнен пилотный проект
          «Контроль торгового зала».
        </p>
        <h4 className="mt-25">Цель</h4>

        <p className="mt-25">
          Проверить и подтвердить готовность инфраструктуры для работы системы.
          Для этого были выбраны две аналитические задачи:
        </p>

        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>
              1. <strong>ТОРГОВЫЙ ЗАЛ:</strong> Подсчёт покупателей, трафик,
              конверсия;
            </li>
            <li>
              2. <strong>КАССА:</strong> Подсчёт количества покупателей в
              очереди, времени ожидания, скорость обслуживания.
            </li>
          </ul>
        </nav>

        <p className="mt-25">
          Источником анализируемого видеопотока являлась существующая система
          видеонаблюдения.{' '}
        </p>

        <h4 className="mt-25">Результат</h4>

        <p className="mt-25">Был проанализирован видеопоток с трёх камер:</p>

        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>1. На входе в мужской отдел</li>
            <li>2. На входе в женский отдел</li>
            <li>3. На кассе</li>
          </ul>
        </nav>

        <div className="d-flex flex-row justify-between mt-40 mb-30">
          <img
            style={{ width: '22vw', height: 'auto' }}
            src="/img/firstProjectAI-1.jpg"
            alt="Photo1"
          />
          <img
            style={{ width: '22vw', height: 'auto' }}
            src="/img/firstProjectAI-2.jpg"
            alt="Photo2"
          />
          <img
            style={{ width: '22vw', height: 'auto' }}
            src="/img/firstProjectAI-3.jpg"
            alt="Photo2"
          />
        </div>

        <h4>Результаты</h4>

        <div className={styles.images_pack}>
          <div>
            <img
              style={{ width: '28vw', height: 'auto' }}
              src="/img/firstProjectAI-4.jpg"
              alt="Photo1"
            />
            <p className="mt-10">Анализ видеопотока</p>
            <p>12.11.2024 с 15:00 до 16:00</p>
            <p>Вошедших 37 человек</p>
            <p>Вышедших 18</p>
          </div>
          <div>
            <img
              style={{ width: '28vw', height: 'auto' }}
              src="/img/firstProjectAI-5.jpg"
              alt="Photo2"
            />
            <p className="mt-10">Анализ видеопотока</p>
            <p>10.11.2024 с 15:30 до 16:30</p>
            <p>Вошедших 122 человек</p>
            <p>Вышедших 126</p>
          </div>{' '}
        </div>
        <div className={styles.images_pack}>
          <div className={styles.last_image}>
            <img
              style={{ width: '32vw', height: 'auto' }}
              src="/img/firstProjectAI-6.jpg"
              alt="Photo3"
            />
            <div className="description">
              <p className="ml-10">
                Анализ видеопотока 24.11.2024 11:03 - 12:03
              </p>
              <p className="ml-10">Обслуженных на кассе 5</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdProjectAI;
