import React from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';

const getDeclinedMonth = (month) => {
  const months = {
    январь: 'января',
    февраль: 'февраля',
    март: 'марта',
    апрель: 'апреля',
    май: 'мая',
    июнь: 'июня',
    июль: 'июля',
    август: 'августа',
    сентябрь: 'сентября',
    октябрь: 'октября',
    ноябрь: 'ноября',
    декабрь: 'декабря',
  };

  return months[month] || month;
};

const Home = () => {
  const currentDate = new Date();

  const currentMonthNominative = currentDate.toLocaleString('default', {
    month: 'long',
  });

  const currentMonthGenitive = getDeclinedMonth(currentMonthNominative);

  const currentYear = currentDate.getFullYear();
  return (
    <div className="content">
      <h2 className="mtk">МТК</h2>
      <div className="mainScreen">
        <h1 className="d-flex">
          Разработка собственных решений в области AI и VR
        </h1>
        <div className="pt-30">
          <div className="d-flex flex-row justify-between big-screen">
            <p id="leftMainInfo" className="d-flex justify-center">
              Интересные задачи побуждают создавать лучшее в мире программное
              обеспечение
            </p>
            <p id="rightMainInfo" className="d-flex justify-center">
              Мы открыты для обсуждения и настроены на результат
            </p>
            <div className="yearInfo d-flex flex-column align-center">
              <h3>2021</h3>
              <div className="redLine"></div>
              <p className="redText">Год основания</p>
            </div>
          </div>
          <div className="d-flex flex-column align-start mobile-screen">
            <p id="leftMainInfo" className="d-flex justify-center">
              Интересные задачи побуждают создавать лучшее в мире программное
              обеспечение
            </p>
            <p id="rightMainInfo" className="d-flex justify-center">
              Мы открыты для обсуждения и настроены на результат
            </p>
            <div className="yearInfo d-flex flex-column align-center">
              <h3>2021</h3>
              <div className="redLine"></div>
              <p className="redText">Год основания</p>
            </div>
          </div>
          <p id="mtk">
            17.04.2024{' '}
            <Link to="projects/ai/second">
              «Программный комплекс для анализа видеопотока на базе
              искусственного интеллекта»
            </Link>
            зарегистрирован в едином реестре российских программ для электронных
            вычислительных машин. Реестровая запись{' '}
            <a
              rel="noreferrer"
              href="https://reestr.digital.gov.ru/reestr/2338021/"
              target="_blank"
            >
              №22178
            </a>{' '}
            от 17.04.2024
          </p>
          <p id="mtk">
            22.05.2024 по результатам экспертизы компании присвоен статус Малая
            Технологическая компания (МТК)
          </p>
        </div>
      </div>
      <div className="infoScreen">
        <h4 className={styles.title}>
          На 1 {currentMonthGenitive} {currentYear}
        </h4>

        <div className="numericInfo d-flex flex-row justify-between big-screen gap-5">
          <div className="numericColumn d-flex flex-column align-center">
            <h3 className={styles.h_num} style={{ color: '#2C2C2C' }}>
              2
            </h3>
            <div className="redLine"></div>
            <p className="redText">Глобальных</p>
            <p className="redText" style={{ marginTop: '-5px' }}>
              направления
            </p>
            <p className="redText" style={{ marginTop: '-5px' }}>
              деятельности
            </p>
          </div>
          <div className="numericColumn d-flex flex-column align-center">
            <h3 className={styles.h_num} style={{ color: '#2C2C2C' }}>
              5
            </h3>
            <div className="redLine"></div>
            <Link className="text-center" to="/projects/ai">
              <p className="redText">Прикладных</p>
              <p className="redText" style={{ marginTop: '-5px' }}>
                проектов
              </p>
              <p className="redText" style={{ marginTop: '-5px' }}>
                в области AI
              </p>
            </Link>
          </div>
          <div className="numericColumn d-flex flex-column align-center">
            <h3 className={styles.h_num} style={{ color: '#2C2C2C' }}>
              3
            </h3>
            <div className="redLine"></div>
            <Link className="text-center" to="projects/vr">
              <p className="redText">Прикладных</p>
              <p className="redText" style={{ marginTop: '-5px' }}>
                проекта
              </p>
              <p className="redText" style={{ marginTop: '-5px' }}>
                в области VR
              </p>
            </Link>
          </div>
          <div className="numericColumn d-flex flex-column align-center">
            <h3 className={styles.h_num} style={{ color: '#2C2C2C' }}>
              5
            </h3>
            <div className="redLine"></div>
            <p className="redText">Объектов</p>
            <p className="redText" style={{ marginTop: '-5px' }}>
              авторских
            </p>
            <p className="redText" style={{ marginTop: '-5px' }}>
              прав
            </p>
          </div>
        </div>

        <div className="numericInfo d-flex flex-column align-center mobile-screen">
          <div className="d-flex flex-column align-center mb-20">
            <h3 className={styles.h_num} style={{ color: '#2C2C2C' }}>
              2
            </h3>
            <div className="redLine"></div>
            <p className="redText">Глобальных</p>
            <p className="redText" style={{ marginTop: '-5px' }}>
              направления
            </p>
            <p className="redText" style={{ marginTop: '-5px' }}>
              деятельности
            </p>
          </div>

          <div className="numericColumn d-flex flex-column align-center">
            <h3 className={styles.h_num} style={{ color: '#2C2C2C' }}>
              5
            </h3>
            <div className="redLine"></div>
            <Link className="text-center" to="/projects/ai">
              <p className="redText">Прикладных</p>
              <p className="redText" style={{ marginTop: '-5px' }}>
                проектов
              </p>
              <p className="redText" style={{ marginTop: '-5px' }}>
                в области AI
              </p>
            </Link>
          </div>

          <div className="d-flex flex-row justify-center">
            <div className="d-flex flex-column align-center mr-20">
              <h3 className={styles.h_num} style={{ color: '#2C2C2C' }}>
                3
              </h3>
              <div className="redLine"></div>
              <Link className="text-center" to="projects/vr">
                <p className="redText">Прикладных</p>
                <p className="redText" style={{ marginTop: '-5px' }}>
                  проекта
                </p>
                <p className="redText" style={{ marginTop: '-5px' }}>
                  в области VR
                </p>
              </Link>
            </div>
          </div>
          <div className="d-flex flex-row justify-center">
            <div className="d-flex flex-column align-center mr-20">
              <h3 className={styles.h_num} style={{ color: '#2C2C2C' }}>
                5
              </h3>
              <div className="redLine"></div>
              <p className="redText">Объектов</p>
              <p className="redText" style={{ marginTop: '-5px' }}>
                авторских
              </p>
              <p className="redText" style={{ marginTop: '-5px' }}>
                прав
              </p>
            </div>
          </div>
        </div>
        <div className="lowerBlock">
          <div className={styles.ai_vr_copyright}>
            <div className={styles.ai_vr}>
              <div className="halfBlock d-flex flex-row align-start">
                <img className="imgLeft_1" src="/img/AI.PNG" alt="DesignAI" />
                <div className="halfBlock__textBlock d-flex flex-column">
                  <h2 className={styles.AI_title}>AI</h2>
                  <p>
                    Решения с использованием технологий компьютерного зрения
                  </p>
                  <p>
                    Решения с использованием технологий предиктивной аналитики
                  </p>
                  <p>
                    Системы поддержки принятия решений для различных направлений
                    деятельности
                  </p>
                  <p>Системы контроля производственных процессов</p>
                </div>
              </div>
              <div className="halfBlock d-flex flex-row align-start">
                <img className="imgLeft" src="/img/VR.PNG" alt="DesignVR" />
                <div className="halfBlock__textBlock d-flex flex-column">
                  <h2>VR</h2>
                  <p>Создание интерактивных VR симуляторов</p>
                  <p>Создание презентационного VR контента</p>
                </div>
              </div>
            </div>
            <div className={styles.copyright}>
              <div className="halfBlock d-flex flex-row align-start">
                <img
                  className="imgRight"
                  src="/img/copyright.svg"
                  alt="DesignVR"
                />
                <div className="halfBlock__textBlock d-flex flex-column">
                  <h2>Copyright</h2>
                  <p>Зарегистрировано в ФИПС 5 патентов на созданное ПО</p>
                  <p>
                    Зарегистрировано в Едином реестре Российских программ для
                    ЭВМ и баз данных 2 программных комплекса.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lowerBlock d-flex flex-column align-start mobile-screen">
          <div className="halfBlock d-flex flex-row align-start">
            <div className="halfBlock__textBlock d-flex flex-column">
              <h2>AI</h2>
              <p>Решения с использованием технологий компьютерного зрения</p>
              <p>Решения с использованием технологий предиктивной аналитики</p>
              <p>
                Системы поддержки принятия решений для различных направлений
                деятельности
              </p>
            </div>
          </div>

          <div className="halfBlock d-flex flex-row align-start">
            <div className="halfBlock__textBlock d-flex flex-column mb-35">
              <h2 className="mt-30">VR</h2>
              <p>Создание интерактивных VR симуляторов</p>
              <p>Создание презентационного VR контента</p>
            </div>
          </div>

          <div className="halfBlock d-flex flex-row align-start">
            <div className="halfBlock__textBlock d-flex flex-column">
              <h2>Copyright</h2>
              <p>Зарегистрировано в ФИПС 5 патентов на созданное ПО</p>
              <p>
                Зарегистрировано в Едином реестре Российских программ для ЭВМ и
                баз данных два программных комплекса.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
