const SecondProductVR = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1>Контроль качества сборки</h1>
        <p className="mt-20">
          Продукт разработан для анализа качества сборки готового изделия. Для
          это производится сравнение реального изображения с компьютерной
          моделью, созданной согласно конструкторской документации.
        </p>

        <div className="d-flex flex-row justify-between mt-40 mb-30">
          <img
            style={{ width: '27vw', height: 'auto' }}
            src="/img/info-products-7.jpg"
            alt="Photo7"
          />
          <img
            style={{ width: '19vw', height: 'auto' }}
            src="/img/info-products-8.jpg"
            alt="Photo8"
          />
          <img
            style={{ width: '18.1vw', height: 'auto' }}
            src="/img/info-products-9.jpg"
            alt="Photo9"
          />
        </div>

        <div className="d-flex flex-row align-center justify-between mt-20 mb-35">
          <p>
            Вывод результата производится, как на AR очки Epson Moverio Pro
            BT-2200, так и в описательный документ, сохраняющийся в БД.
          </p>
          <img
            style={{ width: '12vw', height: 'auto' }}
            src="/img/info-products-10.jpg"
            alt="Photo10"
          />
        </div>
      </div>{' '}
    </div>
  );
};

export default SecondProductVR;
