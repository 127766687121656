import styles from '../styles.module.css';

const ThirdProductVR = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1 className={styles.title}>Система оценки стрессоустойчивойсти</h1>
        <div>
          <p className="mt-20">
            Стресс, связанный с профессиональной деятельностью, представляет две
            основных группы проблем. Первая — это стресс-ассоциированные
            заболевания, которые снижают качество и продолжительность жизни,
            ведут к временной потере трудоспособности. Так по данным Управления
            здравоохранения и безопасности Великобритании, 57% всех «больничных»
            в 2017-2018 годах произошли по вине стресса, депрессии или
            беспокойства (
            <a
              href="https://www.hse.gov.uk/statistics/causdis/stress.pdf"
              target="_blank"
            >
              подробнее здесь
            </a>
            ). В РФ подобной статистики нет, но структура, по всей видимости,
            сходна. Вторая группа — это снижение продуктивности трудовой
            деятельности, допущение ошибок, вплоть до фатальных, связанных с
            человеческими жертвами.
          </p>
          <p className="mt-20">
            В результате выполнения НИОКР проработан прототип
            аппаратно-программного комплекса, предназначенного для проведения
            профессионального тестирования кандидатов на должности, требующие
            высокой стрессоустойчивости. С помощью технологии виртуальной
            реальности, создаются симуляции, имитирующие рабочую деятельность
            испытуемого. Для создания стрессовой ситуации, в сценарий симуляции
            включаются элементы, вызывающие дополнительное психоэмоциональное
            напряжение, в частности, требующие длительной концентрации внимания,
            действий в условиях ограниченного времени, сенсорной перегрузки, а
            также ряд nowin-тестов.
          </p>
          <p className="mt-20">
            Комплекс является альтернативой стресс-интервью. В ходе тестирования
            формируется отчет, содержащий подробные сведения о действиях
            испытуемого в виртуальной среде, показателях психоэмоционального
            напряжения, динамики уровня внимания, утомления, характеристики
            врабатываемости в разрезе типов стресс-ситуаций. HR-специалист и
            руководитель смогут принять обоснованное решение о приеме кандидата
            на работу, зная какие именно ситуации вызывают наибольшую
            психоэмоциональную реакцию, а в каких условиях сотрудник способен
            реагировать конструктивно, сохраняя спокойствие.
          </p>

          <div className="d-flex flex-row justify-around mt-40 mb-35">
            <img
              style={{ width: '15vw', height: 'auto' }}
              src="/img/info-products-11.jpg"
              alt="Photo11"
            />
            <img
              style={{ width: '16.2vw', height: 'auto' }}
              src="/img/info-products-12.jpg"
              alt="Photo12"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdProductVR;
