import React, { useState } from 'react';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Index = (props) => {
  const [showProducts, setShowProducts] = useState(false);
  const [showProjects, setShowProjects] = useState(false);

  return (
    <header className="d-flex justify-between align-center">
      <div
        className={styles.logoBlock + ' d-flex flex-column align-center mt-10'}
      >
        <Link style={{ textDecoration: 'none' }} to="/">
          <img className={styles.logo} src="/img/logo.png" alt="Logo" />
        </Link>
        <Link style={{ textDecoration: 'none' }} to="/">
          <h2 className={styles.header__brand}>Team VR</h2>
        </Link>
      </div>

      <div className={styles.bigScreen}>
        <Link style={{ textDecoration: 'none' }} to="/">
          <h3 className={styles.header__button}>О компании</h3>
        </Link>

        <div
          className={styles.dropdown}
          onMouseEnter={() => setShowProducts(true)}
          onMouseLeave={() => setShowProducts(false)}
        >
          <h3 className={styles.header__button}>Продукты</h3>
          <div
            className={`${styles.dropdownContent} ${
              showProducts ? styles.show : ''
            }`}
          >
            <Link to="/products/ai">Продукты AI</Link>
            <Link to="/products/vr">Продукты VR</Link>
          </div>
        </div>

        <div
          className={styles.dropdown}
          onMouseEnter={() => setShowProjects(true)}
          onMouseLeave={() => setShowProjects(false)}
        >
          <h3 className={styles.header__button}>Проекты</h3>
          <div
            className={`${styles.dropdownContent} ${
              showProjects ? styles.show : ''
            }`}
          >
            <Link to="/projects/ai">Проекты AI</Link>
            <Link to="/projects/vr">Проекты VR</Link>
          </div>
        </div>
      </div>

      <div className={styles.mobileScreen + ' mr-25'}>
        <Button onClick={props.onClickMenu}>
          <img src="/img/mobile-menu.svg" alt="Menu" />
        </Button>
      </div>

      <div className={styles.bigScreen}>
        <Link style={{ textDecoration: 'none' }} to="/contacts">
          <h3 className={styles.header__button}>Контакты</h3>
        </Link>
      </div>
    </header>
  );
};

export default Index;
