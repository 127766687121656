import styles from '../styles.module.css';

const SecondProjectAI = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1>Аналитика заполняемости</h1>
        <p className="mt-25">
          В 2023 году, компания выполнила разработку программного обеспечения и
          пилотный проект на тему «Учёт заполняемости спортивных сооружений».
          Работы выполнялись в рамках исполнения обязательств по госконтракту
          №2023/ОК-п25-77 от 02.10.2023 г. В качестве источников первичной
          информации, используется оборудование существующей системы
          видеонаблюдения. Результатом работы созданной система являются
          аналитические таблицы, графики и диаграммы предназначенные для
          принятия управленческих решений.
        </p>
        <p className="mt-25">Вот, так работает созданное решение:</p>

        <div className="d-flex justify-center">
          <div>
            <img
              style={{ width: '54vw', height: 'auto', marginTop: '30px' }}
              src="/img/secondProjectAI-1.png"
              alt="secondProjectAI-1"
            />
          </div>
        </div>

        <div className={styles.images_pack}>
          <div>
            <img
              style={{ width: '30vw', height: 'auto' }}
              src="/img/secondProjectAI-2.jpg"
              alt="secondProjectAI-2"
            />
          </div>
          <div>
            <img
              style={{ width: '30vw', height: 'auto' }}
              src="/img/secondProjectAI-3.jpg"
              alt="secondProjectAI-3"
            />
          </div>
        </div>

        <div className={styles.images_pack}>
          <div>
            <img
              style={{ width: '30vw', height: 'auto' }}
              src="/img/secondProjectAI-4.jpg"
              alt="secondProjectAI-2"
            />
          </div>
          <div>
            <img
              style={{ width: '30vw', height: 'auto' }}
              src="/img/secondProjectAI-5.jpg"
              alt="secondProjectAI-3"
            />
          </div>
        </div>

        <p className="mt-45">
          В мае 2024 года мы закончили разработку собственного решения для
          анализа «сырых» данных - «Модуль дескриптивной аналитики и
          визуализации». И теперь результаты аналитика заполняемости выглядит
          так:
        </p>

        <div className="d-flex justify-center">
          <div>
            <img
              style={{ width: '56vw', height: 'auto' }}
              src="/img/secondProjectAI-6.png"
              alt="secondProjectAI-6"
            />
          </div>
        </div>

        <p className="mt-25">
          Или так: <strong>Ноябрь 2023г. Всего 12 151 человек</strong>
        </p>

        <div className="d-flex justify-center mt-25">
          <div>
            <img
              style={{ width: '56vw', height: 'auto' }}
              src="/img/secondProjectAI-7.png"
              alt="secondProjectAI-7"
            />
          </div>
        </div>

        <div className="d-flex justify-center mt-25">
          <div>
            <img
              style={{ width: '56vw', height: 'auto' }}
              src="/img/secondProjectAI-8.jpg"
              alt="secondProjectAI-8"
            />
          </div>
        </div>

        <div className="d-flex justify-center mt-25">
          <div>
            <img
              style={{ width: '56vw', height: 'auto' }}
              src="/img/secondProjectAI-9.jpg"
              alt="secondProjectAI-9"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondProjectAI;
