import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const Products = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1>
          Наши продукты <span style={{ color: '#E54246' }}>VR</span>
        </h1>
        <p id="firstP" className="mt-45">
          Ещё в 2016 году, мы начали первые исследования и разработки в области
          VR/AR. Результатом было твёрдое убеждение, что VR/AR можно с успехом
          применять в образовании, в представительских проектах и в
          производстве. И в 2017 году нас пригласили на выставку ОНФ «Форум
          действий», где мы впервые представили свои наработки.
        </p>
        <div className="d-flex flex-row justify-between mt-30 mb-35">
          <img
            style={{ width: '21vw', height: 'auto' }}
            src="/img/info-products-1.jpg"
            alt="Photo1"
          />
          <img
            style={{ width: '21vw', height: 'auto' }}
            src="/img/info-products-2.jpg"
            alt="Photo2"
          />
          <img
            style={{ width: '21vw', height: 'auto' }}
            src="/img/info-products-3.jpg"
            alt="Photo3"
          />
        </div>
        <p id="productsTextTab">
          В настоящее время, в портфеле VR/AR у компании три продукта:
        </p>

        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>
              {' '}
              <Link to="/products/vr/first">Речевой тренажёр</Link>
            </li>
            <li>
              {' '}
              <Link to="/products/vr/second">Контроль качества сборки</Link>
            </li>
            <li>
              {' '}
              <Link to="/products/vr/third">
                Система оценки стрессоустойчивойсти
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Products;
