const FirstProductVR = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1>Речевой тренажер</h1>
        <p className="mt-10">
          Речевой тренажер разработан для использования на уроках английского
          языка на уровне основного общего образования для формирования умений
          общаться в условиях, максимально приближенных к реальным. Тренажер
          предназначен для изучающих иностранный язык на предпороговом уровне,
          что соответствует уровню A2, А1 общеевропейской шкалы владения
          иностранными языками (Common European Framework of Reference – CEFR).
          Погружение в виртуальную реальность дает ощущение личного присутствия.
          Пользователь может свободно передвигаться по виртуальному пространству
          и побывать в разных местах: прогуляться по терминалу аэропорта,
          пройтись по оживленным улицам города, посетить дом представителей
          другой культуры. Погружение в ситуацию, интерактивность, возможность
          манипулировать предметами создает условия для аутентичной
          коммуникации. При этом партнером по общению является виртуальный
          собеседник (аватар), выполняющий роль гида, кассира, хозяина дома и
          т.д.
        </p>

        <div className="d-flex flex-row justify-between mt-40 mb-30">
          <img
            style={{ width: '29vw', height: 'auto' }}
            src="/img/info-products-4.jpg"
            alt="Photo1"
          />
          <img
            style={{ width: '11.15vw', height: 'auto' }}
            src="/img/info-projects-2.jpg"
            alt="Photo2"
          />
          <img
            style={{ width: '14.8vw', height: 'auto' }}
            src="/img/info-products-6.jpg"
            alt="Photo3"
          />
        </div>

        <p className="mt-20">
          Сценарии симуляции основаны на типичных диалогах: знакомство,
          ориентация по городу, осмотр достопримечательностей (на сегодняшний
          день реализовано 6 сценариев). Максимальная длительность симуляций
          составляет 12 минут. Каждый диалог с аватаром длится 2-3 минуты и
          предполагает взаимодействие как с аватаром, так и с объектами
          виртуальной реальности.
        </p>
        <p className="mt-20">
          Эффективность общения можно оценить благодаря разработанному алгоритму
          машинного анализа. Успешность выполнения задания оценивается по
          следующим критериям: полнота ответа, правильность грамматических
          структур, использование обозначенных лексических единиц, понимание
          смысла.
        </p>

        <a
          className="mt-20 mb-15"
          href="https://cloud.mail.ru/public/jY6W/Ro4e9ruBF"
          target="_blank"
        >
          Скачать презентацию
        </a>
      </div>
    </div>
  );
};

export default FirstProductVR;
