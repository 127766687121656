import { Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import Home from './pages/Home';
import { useState } from 'react';
import MobileMenu from './components/MobileMenu';
import Footer from './components/Footer';
import OccupancyAnalytics from './pages/OccupancyAnalytics';
import Contacts from './pages/Contacts';
import ScrollToTop from './components/Scroll/ScrolltoTop';

import ProductsAI from './pages/products-projects/spoilers/ProductsAI';
import ProductsVR from './pages/products-projects/spoilers/ProductsVR';
import ProjectsAI from './pages/products-projects/spoilers/ProjectsAI';
import ProjectsVR from './pages/products-projects/spoilers/ProjectsVR';

import FirstProductVR from './pages/products-projects/productsVR/FirstProductVR';
import SecondProductVR from './pages/products-projects/productsVR/SecondProductVR';
import ThirdProductVR from './pages/products-projects/productsVR/ThirdProductVR';

import FirstProductAI from './pages/products-projects/productsAI/FirstProductAI';
import SecondProductAI from './pages/products-projects/productsAI/SecondProductAI';

import ObjectsOfCopyright from './pages/ObjectsOfCopyright';

import FirstProjectAI from './pages/products-projects/projectsAI/FirstProjectAI';
import SecondProjectAI from './pages/products-projects/projectsAI/SecondProjectAI';
import ThirdProjectAI from './pages/products-projects/projectsAI/ThirdProjectAI';
import FourthProjectAI from './pages/products-projects/projectsAI/FourthProjectAI';

function App() {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  return (
    <div className="App">
      <div className="wrapper">
        <div className="content_all">
          {mobileMenuOpened && (
            <MobileMenu onCloseMenu={() => setMobileMenuOpened(false)} />
          )}
          <Header onClickMenu={() => setMobileMenuOpened(true)} />
          <ScrollToTop />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route
              path="/occupancyAnalytics"
              exact
              element={<OccupancyAnalytics />}
            />
            <Route path="/contacts" exact element={<Contacts />} />
            <Route path="/projects/ai" exact element={<ProjectsAI />} />
            <Route path="/projects/vr" exact element={<ProjectsVR />} />
            <Route path="/products/ai" exact element={<ProductsAI />} />
            <Route path="/products/vr" exact element={<ProductsVR />} />
            <Route
              path="/products/vr/first"
              exact
              element={<FirstProductVR />}
            />
            <Route
              path="/products/vr/second"
              exact
              element={<SecondProductVR />}
            />
            <Route
              path="/products/vr/third"
              exact
              element={<ThirdProductVR />}
            />
            <Route
              path="/products/ai/first"
              exact
              element={<FirstProductAI />}
            />
            <Route
              path="/products/ai/second"
              exact
              element={<SecondProductAI />}
            />

            <Route
              path="/objects-of-copyright"
              exact
              element={<ObjectsOfCopyright />}
            />

            <Route
              path="/projects/ai/first"
              exact
              element={<FirstProjectAI />}
            />

            <Route
              path="/projects/ai/second"
              exact
              element={<SecondProjectAI />}
            />

            <Route
              path="/projects/ai/third"
              exact
              element={<ThirdProjectAI />}
            />

            <Route
              path="/projects/ai/fourth"
              exact
              element={<FourthProjectAI />}
            />
          </Routes>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
