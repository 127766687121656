import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const Products = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1>
          В области AI (Искусственный Интеллект), компания имеет компетенции в
          трех областях:
        </h1>
        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>1. Обработка естественного языка (NLP)</li>
            <li>2. Компьютерное зрение (CV)</li>
            <li>
              3. Предиктивная аналитика» (machine learning и statistical
              modeling)
            </li>
          </ul>
        </nav>
        <p id="firstP" className="mt-45">
          Первые разработки, с применением технологий AI (Искусственного
          Интеллекта), были выполнение в 2018 году. Область работ: «Обработка
          естественного языка» (Natural Language Processing, NLP). По
          результатам исследований, проб и ошибок, созданное программное
          обеспечение было с успехом использовано при разработке речевого
          тренажёра.
        </p>
        <p className="mt-20">
          Вторым направлением работ с применением технологий AI, стала область
          «Компьютерное зрение» (Computer Vision, CV). Толчком к этому послужило
          создание решения «Контроль качества сборки».
        </p>
        <p className="mt-20">
          В 2022 году начались работы в очень интересной области AI
          «Предиктивная аналитика» (machine learning и statistical modeling).
        </p>
        <p className="mt-20">
          В 2023 году были закончены работы по созданию ядра будущей системы
          анализа - «Модуль подсчета количества заданных объектов,
          присутствующих в кадре видеопотока»
        </p>
        <p className="mt-20">
          В 2024 году закончены НИОКР и разработан программно-аппаратный
          комплекс с использованием технологий компьютерного зрения и
          предиктивной аналитики, предназначенный для использования контроля
          работы оборудования на различных производствах.
        </p>
        <h2 className="mt-45">
          По состоянию на 1 февраля 2025г. разработаны и зарегистрированы в
          ФИПС:
        </h2>
        <Link className={styles.productsAILink} to="/products/ai/first">
          Программный комплекс для анализа видеопотока на базе искусственного
          интеллекта
        </Link>
        <p>Использован в 3 проектах.</p>
        <Link className={styles.productsAILink}>
          Модуль дескриптивной аналитики и визуализации
        </Link>
        <p>Использован в 2 проектах.</p>
        <Link className={styles.productsAILink} to="/products/ai/second">
          Система контроля производственных процессов
        </Link>
        <p>
          Использована в 2 проектах. Достигнуто соглашение с производителями
          электротехнического оборудования, о встраивании системы термоконтроля
          в серийно выпускаемую продукцию.
        </p>
      </div>
    </div>
  );
};

export default Products;
