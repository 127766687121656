import styles from '../styles.module.css';

const FirstProjectAI = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1>Аналитика в торговом зале</h1>
        <p className="mt-25">
          На основе разработанного Компанией продукта «Модуль подсчета
          количества заданных объектов, присутствующих в кадре видеопотока» был
          выполнен проект анализа загруженности торгового зала, и эффективности
          работы персонала. Анализ производится в режиме реального времени с
          использованием видеокамер, расположенных в торговом зале. Результаты
          анализа представляйся в виде таблиц, графиков, диаграмм.
        </p>

        <div className="d-flex flex-row justify-between mt-40 mb-30">
          <img
            style={{ width: '29vw', height: 'auto' }}
            src="/img/info-projects-12.jpg"
            alt="Photo1"
          />
          <img
            style={{ width: '29vw', height: 'auto' }}
            src="/img/info-projects-13.jpg"
            alt="Photo2"
          />
        </div>

        <p className="mt-25">
          Результаты анализа используются для оптимизации численности рабочих
          мест и для оценки эффективности работы персонала. Например, такие
          показатели, как:{' '}
        </p>

        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>1. Минимально необходимое количество персонала;</li>
            <li>2. Время обслуживания на кассе одного клиента.</li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default FirstProjectAI;
