import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const Projects = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1>
          По тематике AI (Искусственного Интеллекта), у компании есть хорошие
          компетенции в трёх областях:
        </h1>
        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>1. Обработка естественного языка (NLP)</li>
            <li>2. Компьютерное зрение (CV)</li>
            <li>
              3. Предиктивная аналитика» (machine learning и statistical
              modeling)
            </li>
          </ul>
        </nav>
        <h2>
          В настоящее время, разработанное в этих областях ПО с успехом
          используется в проектах.{' '}
        </h2>

        <br />
        <br />

        <h4 className="mb-25">В области NLP и ML&SL 2018-2024 г.г. </h4>

        <Link className={styles.project}>«Цифровой мир образования»</Link>

        <p className="mt-25 mb-25">
          Разработанное программное обеспечение, используется для поддержки
          интерактивности и оценки уровня прохождения VR симуляции. Разработаны
          оригинальные, нейросетевые методы анализа ответов ученика и
          формирования ответной фразы компьютерного аватара.
        </p>

        <h4 className="mb-25">В области CV (Компьютерное зрение) 2022 год</h4>

        <Link to={'/projects/ai/first'} className={styles.project}>
          «Аналитика в торговом зале»
        </Link>

        <p className="mt-25 mb-25">
          С использованием разработанного Компанией продукта «Модуль подсчета
          количества заданных объектов, присутствующих в кадре видеопотока» был
          выполнен проект анализа загруженности торгового зала, и эффективности
          работы персонала. Анализ производится в режиме реального времени с
          использованием видеокамер, расположенных в торговом зале. Результаты
          анализа используются для оптимизации численности рабочих мест и для
          оценки эффективности работы персонала.{' '}
        </p>

        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>1. Минимально необходимое количество персонала;</li>
            <li>2. Время обслуживания на кассе одного клиента.</li>
          </ul>
        </nav>

        <h4 className="mt-25 mb-25">2023 год</h4>

        <Link to={'/projects/ai/second'} className={styles.project}>
          «Аналитика заполняемости»
        </Link>

        <p className="mt-25 mb-25">
          В 2023году, компания выполнила разработку программного обеспечения и
          пилотный проект на тему «Учёт заполняемости спортивных сооружений».
          Работы выполнялись в рамках исполнения обязательств по госконтракту
          №2023/ОК-п25-77 от 02.10.2023 г. Для этого использовались собственные
          продукты Компании: «Контроль загруженности объектов спорта».{' '}
        </p>

        <h4 className="mt-25 mb-25">2024 год</h4>

        <Link to={'/projects/ai/third'} className={styles.project}>
          «Контроль торгового зала»
        </Link>

        <p className="mt-25 mb-25">
          Пилотный проект выполнен совместно с одной из крупных торговых сетей.
        </p>

        <h2>Цель</h2>
        <h4 className="mt-20">Анализ эффективности работы магазина.</h4>

        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>
              <strong>1. ТОРГОВЫЙ ЗАЛ:</strong> Подсчёт покупателей, трафик,
              конверсия;
            </li>
            <li>
              <strong>2. КАССА:</strong> Количество покупателей в очереди, время
              ожидания, скорость обслуживания.
            </li>
          </ul>
        </nav>

        <p className="mt-25">
          Источником анализируемого видеопотока являлась существующая система
          видеонаблюдения.{' '}
        </p>

        <h2 className="mt-25">Полный функционала системы включает:</h2>

        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>
              - Подсчет посетителей. траффик. Конверсия; *выполнено в пилотном
              проекте;
            </li>
            <li>
              - Касса. Количество покупателей в очереди, время ожидания,
              скорость обслуживания.{' '}
              <strong>выполнено в пилотном проекте</strong>;
            </li>
            <li>- Оценка интереса покупателей к определенному товару;</li>
            <li>- Противодействие “шопинг-террористам”;</li>
            <li>- Оценка работы персонала.</li>
          </ul>
        </nav>

        <h2 className="mt-25 mb-25">2023-2024 г.г.</h2>

        <Link to={'/projects/ai/fourth'} className={styles.project}>
          «Контроль производственных процессов»
        </Link>

        <p className="mt-25">
          Пилотные проекты выполнены на предприятиях электроэнергетической
          области.
        </p>

        <p className="mt-25">
          Человек — главное звено в сложном технологическом процессе, его
          контролёр и регулировщик. Однако человеку присущи естественные
          ограничения и способность совершать ошибки.{' '}
        </p>

        <p className="mt-25">
          Цель проекта - минимизация ручного труда и влияния человеческого
          фактора на важные производственные процессы.{' '}
        </p>

        <p className="mt-25">
          Объект контроля: технологическое оборудование на электроподстанции.
          Оборудование может располагаться, как на открытых площадках, так и в
          закрытых конструкциях.{' '}
        </p>

        <p className="mt-25">
          Вид контроля: контроль рабочей температуры технологического
          оборудования.{' '}
        </p>

        <h2 className="mt-25">РЕЗУЛЬТАТЫ:</h2>

        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>
              1. Исключения «человеческого фактора» и применение алгоритмов
              предиктивной аналитики привело к более эффективному
              прогнозированию аварийных ситуаций и снижению количества аварийных
              отключений оборудования из-за перегрева;
            </li>
            <li>
              2. Сокращение частоты выполнения периодического тепловизионного
              контроля оборудования оперативным персоналом на 60%. (в
              перспективе до 100%);
            </li>
            <li>
              3. После получения статистических данных режимов работы
              оборудования в течение года, (4 сезона: зима, весна, лето, осень)
              создана эффективная нейросетевая модель предиктивной аналитики.
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Projects;
