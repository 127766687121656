import styles from '../styles.module.css';

const FourthProjectAI = () => {
  return (
    <div className="content">
      <div className="infoPages">
        <h1>Контроль производственных процессов</h1>

        <p className="mt-25">
          Цель проекта - минимизация ручного труд и влияния человеческого
          фактора на важные производственные процессы.s
        </p>

        <p className="mt-25">
          Объект контроля: технологическое оборудование на электроподстанции.
          Оборудование может располагаться, как на открытых площадках, так и в
          закрытых шкафах.
        </p>

        <p className="mt-25">
          Вид контроля: контроль рабочей температуры технологического
          оборудования.
        </p>

        <div className={styles.images_pack}>
          <div>
            <img
              style={{ width: '28vw', height: 'auto' }}
              src="/img/fourthProjectAI-1.png"
              alt="fourthProjectAI-1"
            />
            <p className="mt-10">
              Термоконтроль оборудования на открытых площадках
            </p>
          </div>
          <div>
            <img
              style={{ width: '28vw', height: 'auto' }}
              src="/img/fourthProjectAI-2.jpg"
              alt="fourthProjectAI-2"
            />
            <p className="mt-10">
              Термоконтроль оборудования в закрытых ячейках
            </p>
          </div>
        </div>

        <h2 className="mt-25">Инструменты контроля</h2>

        <div className={styles.images_pack}>
          <div>
            <img
              style={{ width: '28vw', height: 'auto' }}
              src="/img/fourthProjectAI-3.jpg"
              alt="fourthProjectAI-3"
            />
            <p className="mt-10">
              На открытых площадках используется Двухспектральная поворотная
              платформа с тепловизионным модулем
            </p>
          </div>
          <div>
            <img
              style={{ width: '28vw', height: 'auto' }}
              src="/img/fourthProjectAI-4.png"
              alt="fourthProjectAI-4"
            />
            <p className="mt-10">
              В закрытых шкафах используется беспроводной тепловизионный модуль
              собственно производства СISD-1001
            </p>
          </div>
        </div>

        <h2 className="mt-25">Результаты контроля</h2>

        <div className={styles.images_pack}>
          <div>
            <img
              style={{ width: '28vw', height: 'auto' }}
              src="/img/fourthProjectAI-5.png"
              alt="fourthProjectAI-5"
            />
          </div>
          <div>
            <img
              style={{ width: '28vw', height: 'auto' }}
              src="/img/fourthProjectAI-6.png"
              alt="fourthProjectAI-6"
            />
          </div>
        </div>
        <div className={styles.images_pack}>
          <div>
            <img
              style={{ width: '38vw', height: 'auto', marginTop: '30px' }}
              src="/img/fourthProjectAI-7.png"
              alt="fourthProjectAI-7"
            />
          </div>
        </div>

        <h2 className="mt-25">Результаты</h2>

        <nav className={styles.products_navbar}>
          <ul className={styles.products}>
            <li>
              1. Создание системы непрерывного мониторинга оборудования ОРУ на
              предмет нарушения температурного режима работы узлов и агрегатов;
            </li>
            <li>
              2. Исключения «человеческого фактора» и применение алгоритмов
              предиктивной аналитики привело к более эффективному
              прогнозированию аварийных ситуаций и снижению количества аварийных
              отключений оборудования из-за перегрева;
            </li>
            <li>
              3. Сокращение частоты выполнения периодического тепловизионного
              контроля оборудования оперативным персоналом на 60%. (в
              перспективе до 100%)
            </li>
            <li>
              4. После получения статистических данных режимов работы
              оборудования в течение года (4 сезона: зима, весна, лето, осень)
              создана эффективная нейросетевая модель работы оборудования.
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default FourthProjectAI;
